// utils.js

import {getActivatedInsuranceInformation} from "../hooks/ClientPortal";

const apiUrl = process.env.REACT_APP_API_PUBLIC_PATH; // or process.env.REACT_APP_API_PATH if that contains your base URL

export const getImageUrl = (imgURL) => {
    return imgURL.startsWith('/uploads/') ? `${apiUrl}${imgURL}` : imgURL;
};

export const updateCartCount = () => {
    let products = localStorage.getItem("products");
    let cartCount = JSON.parse(products);

    let cartIcon = document.getElementById("buy-more");
    if (cartIcon) {
        if (cartCount && cartCount.length > 0) {
            let count = cartCount.length;
            cartIcon.setAttribute("data-badge", count)
        } else {
            cartIcon.removeAttribute("data-badge");
        }
    }
}

export const checkIfInsuranceRequired = async (token) => {
    try {
        const activatedInsuranceInformation = await getActivatedInsuranceInformation(token);
        if (activatedInsuranceInformation && activatedInsuranceInformation.status === 'success') {
            const validationChecks = validateIfAttentionRequired(activatedInsuranceInformation.data);
            let insuranceActivation = document.getElementById("insurance-activation");

            if (insuranceActivation && Object.keys(validationChecks).length > 0) {
                if (activatedInsuranceInformation.data && Object.keys(activatedInsuranceInformation.data).length > 0) {
                   insuranceActivation.setAttribute("data-badge", "!")
                } else {
                   insuranceActivation.removeAttribute("data-badge");
                }
            }
        } else {
            console.error('Failed to fetch data');
        }
    } catch (error) {
        console.error('Error fetching form data:', error);
    }
}

export const formatDate = (dateString) => {
    const options = {year: "numeric", month: "long", day: "numeric"};
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
}

export const validateIfAttentionRequired = (insuranceFormData) => {
    const validationErrors = {};

    Object.keys(insuranceFormData).forEach((key) => {
        const users = insuranceFormData[key]?.users || [];

        const missingInsurance = [];
        users.forEach((user, index) => {
            if (!user.insurance_covered_entity_id) {
                missingInsurance[index] = {...missingInsurance[index], insurance_covered_entity_id: 'Insurance requires activation'};
            }
        });

        if (missingInsurance.length > 0) {
            validationErrors[key] = missingInsurance;
        }
    });

    return validationErrors;
}