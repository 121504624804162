import React, {useCallback, useEffect, useState} from "react";
import {MdDelete} from "react-icons/md";
import "./Portal.css";
import {
    deleteUserInsurance,
    getActivatedInsuranceInformation,
    saveUserInsurance
} from "../../hooks/ClientPortal";
import LoadingComponent from "../../components/LoadingComponent";
import {verifyIfKeycloakInit} from "../../Keycloak";

const ActivateInsurance = () => {
    const [insuranceFormData, setInsuranceFormData] = useState({});
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);

    const token = verifyIfKeycloakInit();

    const getActivatedInsuranceData = useCallback(async () => {
        if (token) {
            setLoading(true);

            try {
                const activatedInsuranceInformation = await getActivatedInsuranceInformation(token);
                if (activatedInsuranceInformation && activatedInsuranceInformation.status === 'success') {
                    setInsuranceFormData(activatedInsuranceInformation.data);
                } else {
                    setErrors(activatedInsuranceInformation ? activatedInsuranceInformation.data : 'Unknown error');
                }
            } catch (error) {
                setErrors(error.message || 'An unknown error occurred');
                console.error('Error fetching form data:', error);
            } finally {
                setLoading(false);
            }
        }
    }, [token]);

    useEffect(() => {
        getActivatedInsuranceData();
    }, [getActivatedInsuranceData]);

    function handleAlertClose() {
        setMessage(null);
        setError(null);
        setValidationErrors({})
        setShowAlert(false);
    }

    const handleChange = (e, key, index) => {
        const {name, value} = e.target;

        const updatedUsers = [...insuranceFormData[key].users];
        updatedUsers[index] = {
            ...updatedUsers[index],
            [name]: value,
        }

        setInsuranceFormData((prevState) => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                users: updatedUsers,
            },
        }))
    };

    async function handleRemoveUser(insurance_id) {
        if (!insurance_id) {
            console.error("No insurance_id provided");
            return null;
        }

        setLoading(true);

        try {
            const response = await deleteUserInsurance(token, insurance_id);
            if (response) {
                if (response.status === 'success') {
                    setMessage("Information deleted successfully");
                    setShowAlert(true);

                    await getActivatedInsuranceData()
                } else {
                    setMessage("An error occurred while updating account information");
                    setShowAlert(true);
                }
            }
        } catch (error) {
            setError("An error occurred while updating account information");
        } finally {
            setLoading(false);
        }
    }

    const validateForm = () => {
        const validationErrors = {};

        Object.keys(insuranceFormData).forEach((key) => {
            const keyErrors = [];
            const users = insuranceFormData[key]?.users || [];

            users.forEach((user, index) => {
                if (!user.user_first_name) {
                    keyErrors[index] = {...keyErrors[index], user_first_name: 'First Name is required'};
                }

                if (!user.user_last_name) {
                    keyErrors[index] = {...keyErrors[index], user_last_name: 'Last Name is required'};
                }

                if (!user.user_email_address) {
                    keyErrors[index] = {...keyErrors[index], user_email_address: 'Email Address is required'};
                } else if (!/\S+@\S+\.\S+/.test(user.user_email_address)) {
                    keyErrors[index] = {...keyErrors[index], user_email_address: 'Email Address is not valid'};
                }
            });

            if (keyErrors.length > 0) {
                validationErrors[key] = keyErrors;
            }
        });

        return validationErrors;
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setValidationErrors({});
        setShowAlert(false);

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setValidationErrors(validationErrors);
            setShowAlert(true);
            return;
        }

        if (token) {
            setLoading(true);
            try {
                const response = await saveUserInsurance(token, insuranceFormData);
                if (response && response.status === 'success') {
                    setMessage('Information saved successfully');
                    setShowAlert(true);

                    await getActivatedInsuranceData()
                } else {
                    setError('An error occurred while saving insurance information');
                    setShowAlert(true);
                }
            } catch (error) {
                setError('An error occurred while saving insurance information');
                setShowAlert(true);
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <div className="dashboard-main-container">
            {loading && <LoadingComponent/>}

            <div className="container-fluid section-spacing">
                <form onSubmit={handleSubmit}>
                    <div className="card-information-container">
                        <div className="row section-spacing-bottom information-row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <h1>Insurance Activation</h1>
                                <h6 className="mb-3">Manage your Insurance Activation Information</h6>
                            </div>

                            <div
                                className="col-lg-6 col-md-6 col-sm-12 col-12 d-inline-flex align-items-center justify-content-end">
                                <div className="action-container">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block me-2">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-12">
                                {showAlert && message && (
                                    <div className="message-container">
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            {message}
                                            <button type="button" className="btn-close"
                                                    onClick={handleAlertClose}></button>
                                        </div>
                                    </div>
                                )}

                                {showAlert && error && (
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        {error}
                                        <button type="button" className="btn-close" onClick={handleAlertClose}></button>
                                    </div>
                                )}

                                {Object.keys(errors ?? {}).length !== 0 && (
                                    <div className="error-description-container text-center">
                                        {errors || "Sorry, we couldn't find what you were looking for. Please double-check the information and try again!"}
                                    </div>
                                )}

                                {showAlert && Object.keys(validationErrors).length !== 0 && (
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        Please fill out all required fields before submitting
                                        <button type="button" className="btn-close" onClick={handleAlertClose}></button>
                                    </div>
                                )}
                            </div>
                        </div>

                        {
                            Object.keys(insuranceFormData).map((key) => (
                                <section key={key} className="mb-5">
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <h5 className="mb-3">Please activate your insurance(s) for {key}</h5>
                                            {
                                                insuranceFormData[key].cancelled > 0 && (
                                                    <h6 className="mb-3 important-information">
                                                        You cancelled {insuranceFormData[key].cancelled} subscriptions.
                                                        Please
                                                        remember to update active licenses.
                                                    </h6>
                                                )
                                            }
                                        </div>
                                    </div>

                                    {
                                        insuranceFormData[key].users.length > 0 ? insuranceFormData[key].users.map((user, index) => (
                                            <div className="row mb-3" key={index}>
                                                <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                                    <div className="form-input-container mb-3">
                                                        <div className="form-heading-container mb-2">
                                                            First Name *
                                                        </div>

                                                        <input disabled={user.id}
                                                               type="text"
                                                               className="form-control checkout-inputs"
                                                               name="user_first_name"
                                                               value={user.user_first_name ?? ''}
                                                               onChange={(e) => handleChange(e, key, index)}
                                                               placeholder="First Name"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                                    <div className="form-input-container mb-3">
                                                        <div className="form-heading-container mb-2">
                                                            Last Name *
                                                        </div>

                                                        <input disabled={user.id}
                                                               type="text"
                                                               className="form-control checkout-inputs"
                                                               name="user_last_name"
                                                               value={user.user_last_name ?? ''}
                                                               onChange={(e) => handleChange(e, key, index)}
                                                               placeholder="Last Name"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                                    <div className="form-input-container mb-3">
                                                        <div className="form-heading-container mb-2">
                                                            Email Address *
                                                        </div>
                                                        <input disabled={user.id}
                                                               type="email"
                                                               className="form-control checkout-inputs"
                                                               name="user_email_address"
                                                               value={user.user_email_address ?? ''}
                                                               onChange={(e) => handleChange(e, key, index)}
                                                               placeholder="Email Address"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                                                    <div className={`form-input-container mb-3 ${user.id ? '' : 'd-none'}`}>
                                                        <div className="form-heading-container mb-2">
                                                            Insurance Covered ID
                                                        </div>

                                                        <input disabled
                                                               type="text"
                                                               className="form-control checkout-inputs"
                                                               name="insurance_covered_entity_id"
                                                               value={user.insurance_covered_entity_id ?? ''}
                                                               onChange={(e) => handleChange(e, key, index)}
                                                               placeholder="Insurance Covered ID"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-1 col-md-6 col-sm-12 col-12 content-middle">
                                                    <MdDelete
                                                        className={`delete-insurance-line cursor-pointer ${user.id ? '' : 'd-none'}`}
                                                        onClick={() => handleRemoveUser(user.id)}
                                                    />
                                                </div>
                                            </div>
                                        )) : ""
                                    }
                                </section>
                            ))
                        }
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ActivateInsurance;